.add-user-form {
    .form-add {
        text-align: center;

        .ant-form-item {
            margin-top: 10px;
            margin-bottom: 10px;

            // .anticon{
            //     color: rgba(0, 0, 0, 0.25);
            // }
        }
    }
}
